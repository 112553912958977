module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-analytics@5.13.1_gatsby@5.13.5_babel-eslint@10.1.0_eslint@7.32.0__encodi_2qo3gzxn7ql5ewl24xl6klqlwm/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-157161723-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.5_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.1_3pru2u5ep2pthpjldgwf6xmb7a/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"parsons-locksmith","short_name":"parsons","start_url":"/","background_color":"#2A2B2D","theme_color":"#2A2B2D","display":"minimal-ui","icon":"src/assets/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c8686f111115ab529e71d987b9073ad7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.5_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._hjy4qvsx33ihrnirohplkqzyy4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
